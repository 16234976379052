/** @jsxRuntime classic */
/** @jsx jsx */
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import theme from "gatsby-plugin-theme-ui"
import { jsx, ThemeUIStyleObject } from "theme-ui"

import type { Maybe } from "../../../graphql-types"

interface MdContentProps {
  className?: string
  source: Maybe<string> | undefined
}

const MdContent = ({ className, source }: MdContentProps) => {
  return (
    <ReactMarkdown
      className={className}
      children={source as string}
      rehypePlugins={[rehypeRaw]}
      sx={{ ...(theme.styles as ThemeUIStyleObject) }}
      components={{
        a({ href, children, ...props }) {
          let cdn = false,
            link = href

          if (href) {
            cdn = new RegExp(/^(\/uploads).*/).test(href)
          }

          if (cdn) {
            link = `${process.env.GATSBY_CDN_URL}${link}`
          }

          if (typeof href !== "undefined") {
            const expression =
              /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
            const regex = new RegExp(expression)

            if (href.match(regex)) {
              return (
                <a target="_blank" rel="noopener noreferrer" href={link}>
                  {children}
                </a>
              )
            } else {
              return <a href={link}>{children}</a>
            }
          }

          return (
            <a href={link} {...props}>
              {children}
            </a>
          )
        },
        img({ src, alt, ...props }) {
          const realSrc = `${process.env.GATSBY_API_URL}${src}`
          return <img src={realSrc} alt={alt} />
        },
      }}
    />
  )
}

export default MdContent
